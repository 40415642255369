










































































































































import  Vue from "vue";
import { getModule } from "vuex-module-decorators";

import analyticsVuexModule from "@/store/vuex-modules/admin-analytics";
const analyticsStore = getModule(analyticsVuexModule);

import Spinners from "@/components/utilities/Spinners.vue";
import AnalyticsDateControl from "./AnalyticsDateControl.vue";

import { transformDataToCsvUrl } from "@/services/csv";
import { AnalyticsExhibitorOverview, AnalyticsVisitorWithHits } from "@/types/interfaces";

export default Vue.extend ({
    components: {
        Spinners,
        AnalyticsDateControl
    },
    computed: {

        loading(): boolean {
            return analyticsStore.loading;
        },
    
        visits(): Array<AnalyticsExhibitorOverview> {
            return analyticsStore.exhibitorData.exhibVisitors;
        },
    
        landingVisits(): Array<AnalyticsVisitorWithHits> {
            return analyticsStore.exhibitorData.landingUsers;
        },
    
        sortedVisits(): Array<AnalyticsExhibitorOverview> {
            return this.sortedVisitsMethod();
        },
    
        tableHeaders(): Array<string> {
            return [
                "Exhibitor ID",
                "Exhibitor Name",
                "Total Visits",
                "Unique Visitors"
            ];
        },
    
        tableFooter(): Array<string> {
            return ["Total", "", `${this.totalHits}`, `${this.totalUniques}`];
        },
    
        flatLandingData(): Array<any> {
            return this.landingVisits.map((visitor) => [
                visitor.uid,
                visitor.firstName,
                visitor.lastName,
                visitor.companyName,
                visitor.email,
                `${visitor.hits}`
            ]);
        },
    
        landingTableHeaders(): Array<string> {
            return [
                "Attendee ID",
                "First Name",
                "Last Name",
                "Company Name",
                "Email",
                "Visits"
            ];
        },
    
        landingTableFooter(): Array<string> {
            return ["Total Visits", "", "", "", "", `${this.totalLandingHits}`];
        },
    
        totalHits(): number {
            return this.visits.reduce((acc, curr) => {
                return (acc += curr.hits);
            }, 0);
        },
    
        totalUniques(): number {
            return this.visits.reduce((acc, curr) => {
                return (acc += curr.uniques);
            }, 0);
        },
    
        totalLandingHits(): number {
            return this.landingVisits.reduce((acc, curr) => {
                return (acc += curr.hits);
            }, 0);
        },
    
        csvUrl(): string {
            return this.getCsvUrl();
        },
    
        landingCsvUrl(): string {
            return this.getLandingCsvUrl();
        }
    },
    methods: {
        sortedVisitsMethod() {
            return this.visits.sort((a, b) => b.hits - a.hits);
        },

        getData() {
            analyticsStore.getExhibitorData();
        },
    
        getCsvUrl() {
            const valuesOnly = this.visits.map((item) => [
                item.sponsorId,
                item.sponsorName,
                item.hits ? `${item.hits}` : "0",
                item.uniques ? `${item.uniques}` : "0"
            ]);
            const dataArray = [this.tableHeaders, ...valuesOnly, this.tableFooter];
    
            return transformDataToCsvUrl(dataArray);
        },
    
        getLandingCsvUrl() {
            const dataArray = [
                this.landingTableHeaders,
                ...this.flatLandingData,
                this.landingTableFooter
            ];
            return transformDataToCsvUrl(dataArray);
        }

    },

    mounted() {
        this.getData();
    }

})
